<script setup lang="ts">
import {
  GeneralIcons,
  BrandIcon,
  Btn,
  Illustrations,
} from 'webcc-ui-components'
import type { NuxtError } from '#app'

const props = defineProps<{
  error: NuxtError
}>()

const { t } = useI18n()

const hasNotFoundError = computed(() => props.error.statusCode === 404)

const variant = computed(() => (hasNotFoundError.value ? '404' : '500'))

const title = computed(() =>
  hasNotFoundError.value
    ? t('components.navigation.doesNotExist')
    : t('components.error.internalErrorDescription'),
)

const label = computed(() =>
  hasNotFoundError.value
    ? t('components.navigation.goHomepage')
    : t('components.actions.reloadPage'),
)

function handleClick() {
  if (hasNotFoundError.value) {
    clearError({ redirect: useNuxtApp().$ROUTE.INDEX })
  } else {
    window.location.reload()
  }
}
</script>

<template>
  <div class="flex flex-col min-h-screen relative">
    <LayoutHeaderLoggedIn v-if="$auth.loggedIn" />
    <LayoutHeaderLoggedOut v-else />

    <div
      class="flex-full-h items-center justify-center py-20 px-4 md:py-24"
      :class="{ 'gap-y-12': variant }"
    >
      <Illustrations v-if="variant" :variant="variant" class="w-60" />

      <div class="grid place-items-center gap-y-4">
        <p class="text-center max-w-80 leading-4.5 text-sm text-txt-300">
          {{ title }}
        </p>

        <Btn
          class="w-max"
          variant="neutral"
          size="md"
          outlined
          :label="label"
          @click="handleClick"
        />
      </div>
    </div>

    <OnDevices v-if="hasNotFoundError" rule=">=md">
      <SupportCenterButtonDesktop
        v-if="$auth.loggedIn"
        class="sticky bottom-8 right-8 z-10 -mt-[76px] mb-8 mr-8 flex justify-end"
      />
    </OnDevices>

    <LayoutPreFooter />
    <LayoutFooter />

    <GeneralIcons />
    <BrandIcon />
  </div>
</template>
