import { reactive } from 'vue'
import { debounce } from 'lodash-es'
import { defineNuxtPlugin, useRequestHeaders } from '#imports'

interface TouchPluginContext {
  supports: boolean
}

export default defineNuxtPlugin<TouchPluginContext>({
  setup() {
    const $touch = reactive({ supports: false })
    const userAgent = useRequestHeaders()?.['user-agent'] || ''

    if (import.meta.client) {
      $touch.supports = isBrowserTouch()
      const onResize = debounce(() => {
        $touch.supports = isBrowserTouch()
      }, 200)
      window.addEventListener('resize', onResize, { passive: true })
    } else {
      $touch.supports = isUserAgentTouch(userAgent)
    }

    return {
      provide: {
        touch: $touch,
      },
    }
  },
})

export function isBrowserTouch() {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia('(pointer: coarse)').matches
  )
}

export function isUserAgentTouch(useAgent: string) {
  const regexMatch = useAgent
    .toLowerCase()
    .match(/(iphone|ipod|ipad|android|iemobile|touch)/i)

  return Array.isArray(regexMatch)
}

declare module '#app' {
  interface NuxtApp {
    $touch: TouchPluginContext
  }
}
