import type { MaybeRef } from 'vue'

export function useDynamicBodyClass(flag: MaybeRef, className: string): void {
  if (!import.meta.client) return

  watch(
    () => unref(flag),
    (flagStatus) => {
      if (flagStatus) {
        document.body.classList.add(className)
      } else {
        document.body.classList.remove(className)
      }
    },
    { immediate: true },
  )

  onBeforeUnmount(() => {
    document.body.classList.remove(className)
  })
}
