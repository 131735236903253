import validate from "/myapp/node_modules/.pnpm/nuxt@3.12.2_@unocss+reset@0.61.0_eslint@8.56.0_floating-vue@5.2.2_rollup@4.18.0_typescript@5._w2wfrbk5fxwapx4loti6ocya3u/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth from "/myapp/src/modules/auth/runtime/middleware/auth.ts";
import manifest_45route_45rule from "/myapp/node_modules/.pnpm/nuxt@3.12.2_@unocss+reset@0.61.0_eslint@8.56.0_floating-vue@5.2.2_rollup@4.18.0_typescript@5._w2wfrbk5fxwapx4loti6ocya3u/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "payments-middleware": () => import("/myapp/src/middleware/payments-middleware.ts")
}