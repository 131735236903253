import { defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: 'route',
  enforce: 'pre',
  setup() {
    const $ROUTE = routesConst()
    const $GUEST_ALLOWED_ROUTE = guestAllowedRoutes()
    return {
      provide: {
        ROUTE: $ROUTE,
        GUEST_ALLOWED_ROUTE: $GUEST_ALLOWED_ROUTE,
      },
    }
  },
})

function routesConst() {
  return {
    BENEFITS: '/benefits',
    BOOKINGS_CALENDAR: '/bookings/calendar',
    CONTACT_LIST: '/contact-list',
    CONTACT_US: '/contact-us',
    CONTACT_US_ERROR: '/contact-us/error',
    CONTACT_US_SUCCESS: '/contact-us/success',
    CONTRACT_PRICES: '/contract-prices',
    GUIDELINES: '/guidelines',
    INBOX: '/inbox',
    INDEX: '/',
    OPPORTUNITIES: '/opportunities',
    PAYMENTS: '/payments',
    PAYMENTS_OVERVIEW: '/payments-overview',
    PAYMENTS_STATEMENTS: '/payments-overview/statements',
    PROFILE: '/profile',
    PROFILE_CHANGE_ADDRESS: '/profile/change-address',
    PROFILE_CHANGE_BANK_DETAILS: '/profile/payment-info/change-bank-details',
    PROFILE_CHANGE_PASSWORD: '/profile/change-password',
    PROFILE_CHANGE_PHONE: '/profile/change-phone',
    PROFILE_NOTIFICATIONS: '/profile/notifications',
    PROFILE_PAYMENT_INFO: '/profile/payment-info',
    PROPERTY_BENCHMARK: '/property-benchmark',
    RESERVATIONS: '/reservations',
    RESERVATIONS_ARRIVALS: '/reservations/arrivals',
    RESERVATIONS_DEPARTURES: '/reservations/departures',
    RESOURCES: '/resources',
    REVIEWS: '/reviews',
    DOCUMENT_DOWNLOADS: '/document/downloads',
    ...guestAllowedRoutes(),
  }
}

function guestAllowedRoutes() {
  return {
    CHANGE_PASSWORD_CONFIRMATION: '/change-password-confirmation',
    FORGOT_NUMBER: '/forgot-number',
    FORGOT_NUMBER_CONFIRMATION: '/forgot-number/success',
    FORGOT_PASSWORD: '/forgot-password',
    FORGOT_PASSWORD_CONFIRMATION: '/forgot-password/success',
    LOGIN: '/login',
    RESET_PASSWORD: '/reset-password',
    TWO_FACTOR_AUTHENTICATION: '/2fa',
    TWO_FACTOR_AUTHENTICATION_CONTACT_US: '/2fa/contact-us',
    TWO_FACTOR_AUTHENTICATION_CONTACT_US_ERROR: '/2fa/contact-us/error',
    TWO_FACTOR_AUTHENTICATION_CONTACT_US_SUCCESS: '/2fa/contact-us/success',
    TWO_FACTOR_AUTHENTICATION_ERROR: '/authentication-error',
  }
}

declare module '#app' {
  interface NuxtApp {
    $ROUTE: ReturnType<typeof routesConst>
    $GUEST_ALLOWED_ROUTE: ReturnType<typeof guestAllowedRoutes>
  }
}
