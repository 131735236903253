<template>
  <header class="bg-bgr-100">
    <div
      class="mx-auto flex w-full max-w-[1272px] items-center justify-between px-4 pb-4 pt-2.5 md:h-[76px] md:px-6 md:pt-4 lg:px-8 2xl:px-0"
    >
      <OnDevices rule=">=md">
        <LayoutBrandLogos />
      </OnDevices>

      <LanguageSwitch />

      <OnDevices rule="<md">
        <BasicOldOwnerPortalButton />
      </OnDevices>
    </div>
  </header>
</template>
