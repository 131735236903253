export function useHeaderTracking() {
  const { $gtm, $i18n } = useNuxtApp()
  const route = useRoute()
  const getCommonParams = () => ({
    event: 'header',
    component: 'header',
    language: unref($i18n.locale),
    _clear: true,
  })

  function trackInboxBellClick() {
    $gtm.trackEvent({
      action: 'navigate',
      label: 'bell icon',
      category: 'inbox',
      position: route.path,
      ...getCommonParams(),
    })
  }

  function trackLanguageChange(lang: string) {
    $gtm.trackEvent({
      action: 'change language',
      label: lang,
      category: undefined,
      position: route.path,
      ...getCommonParams(),
    })
  }

  function trackMyProfileClick() {
    $gtm.trackEvent({
      action: 'navigate',
      label: 'account icon',
      category: 'My Profile',
      position: route.path,
      ...getCommonParams(),
    })
  }

  function trackLogOutClick() {
    $gtm.trackEvent({
      action: 'logout',
      label: 'account icon',
      category: 'Logout',
      position: route.path,
      ...getCommonParams(),
    })
  }

  return {
    trackInboxBellClick,
    trackLanguageChange,
    trackMyProfileClick,
    trackLogOutClick,
  }
}
