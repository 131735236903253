<script setup lang="ts">
const error = useError()
const hasNotFoundPageError = computed(() => {
  return error.value?.statusCode === 404 ? false : !!error.value?.message
})
</script>

<template>
  <header class="bg-bgr-100">
    <div
      class="mx-auto flex w-full max-w-[1272px] items-center justify-between px-4 pb-4 pt-2.5 md:h-[76px] md:px-6 md:pt-4 lg:px-8 2xl:px-0"
    >
      <OnDevices rule=">=md">
        <LayoutBrandLogos />
      </OnDevices>

      <div
        v-if="!hasNotFoundPageError"
        class="flex w-full justify-between gap-6 md:w-auto"
      >
        <LanguageSwitch />

        <OnDevices rule="<md">
          <BasicOldOwnerPortalButton class="self-center" />
        </OnDevices>

        <div class="flex items-center gap-4">
          <OnDevices rule="<md">
            <SupportCenterButtonMobile />
          </OnDevices>

          <InboxIconButton class="flex items-center" />
        </div>
      </div>
    </div>
  </header>
</template>
