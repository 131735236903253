export type NavigationMenuItem = {
  icon: string | null
  label: string
  url: string | null
  hide?: boolean
  isToggleMenuButton?: boolean
}

export type MenuItem = {
  title: string
  routeName?: string
  url?: string
  subcategory?: string
  hide?: boolean
  event?: string
  children?: MenuItem[]
  action: Function
}

const isModalOpen = ref(false)

export function useNavigation() {
  const route = useRoute()
  const routePath = ref<string | null>(route.path)

  watch(
    () => route.path,
    () => {
      if (route.path !== routePath.value) {
        routePath.value = route.path
      }
    },
  )

  function navigate(item: NavigationMenuItem) {
    if (item.isToggleMenuButton) {
      isModalOpen.value = !isModalOpen.value
    } else if (item.url) {
      routePath.value = item.url
      navigateTo(item.url)

      isModalOpen.value = false
    }
  }

  function setModalOpen(flag: boolean) {
    isModalOpen.value = flag
  }

  return { navigate, setModalOpen, isModalOpen, routePath }
}
