<script setup lang="ts">
import { Card, Icon } from 'webcc-ui-components'

const emit = defineEmits<{
  (e: 'click'): void
}>()

const { t } = useI18n()
const { $ROUTE } = useNuxtApp()
const { open: openErv } = useErv()

const floatingMenuOptions = computed(() => {
  return [
    {
      name: t('components.common.contactList'),
      handle: () => navigateTo($ROUTE.CONTACT_LIST),
    },
    {
      name: t('components.navigation.contactInsurance'),
      handle: openErv,
      icon: 'share-box',
    },
    {
      name: t('components.common.contactUs'),
      handle: () => navigateTo($ROUTE.CONTACT_US),
    },
  ]
})

function handleClick(handle: () => any) {
  handle()

  emit('click')
}
</script>

<template>
  <div data-id="support-center-menu">
    <Card
      v-for="{ name, handle, icon } in floatingMenuOptions"
      :key="name"
      class="flex items-center justify-between space-x-4 md:p-4"
      :class="icon ? 'md:p-4' : '!py-[22px]'"
      @click="handleClick(handle)"
    >
      <span class="text-base font-medium leading-4.5">
        {{ name }}
      </span>

      <div v-if="icon" class="px-1 py-[3px]">
        <Icon size="md" graphic="share-box" class="text-txt-400" />
      </div>
    </Card>
  </div>
</template>
