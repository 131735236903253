import { useQuery } from '@tanstack/vue-query'
import type { MaybeRef } from 'vue'
import {
  type components,
  NotificationType,
  RenewalNotificationProcessType,
} from '~/schema'

export type InboxNotificationResponse =
  components['schemas']['NotificationsResponse']
export type InboxStatusResponse = components['schemas']['InboxStatus']
export type ReservationNotification =
  components['schemas']['ReservationNotification']
export type RenewalNotification = components['schemas']['RenewalNotification']
export type ContractNotification = components['schemas']['ContractNotification']
export type Notification = components['schemas']['Notification']
export type NotificationCard = Omit<
  components['schemas']['Notification'],
  'reservation' | 'renewal' | 'contract'
> &
  (
    | ({
        type: NotificationType.RESERVATION
      } & Required<Pick<Notification, 'reservation'>>)
    | ({
        type: NotificationType.RENEWAL
      } & Required<Pick<Notification, 'renewal'>>)
    | ({
        type: NotificationType.CONTRACT
      } & Required<Pick<Notification, 'contract'>>)
  )

export function useInboxNotificationsQuery() {
  const isNotificationIconVisible = useState(
    'isNotificationIconVisible',
    () => false,
  )

  const fetch = useApiFetch()

  const queryResult = useQuery({
    queryKey: ['inbox-notifications'] as const,
    queryFn({ signal }) {
      return fetch<InboxNotificationResponse>('/api/inbox/notifications', {
        signal,
      })
    },
    staleTime: 20 * 1000, // 20 second delay
    select(data: InboxNotificationResponse): Notification[] {
      return data.content ?? ([] as Notification[])
    },
  })

  watchImmediate(queryResult.data, () => {
    isNotificationIconVisible.value = false
  })

  return queryResult
}

export function useFetchInboxStatus(params: { enabled: MaybeRef<boolean> }) {
  const fetch = useApiFetch()

  const isNotificationIconVisible = useState(
    'isNotificationIconVisible',
    () => false,
  )

  const queryResult = useQuery({
    staleTime: 0,
    enabled: import.meta.client && params.enabled,
    queryKey: ['inbox-status'],
    queryFn({ signal }) {
      return fetch<InboxStatusResponse>('/api/inbox/status', { signal })
    },
  })

  import.meta.client &&
    watch(
      queryResult.data,
      (data) =>
        (isNotificationIconVisible.value = data?.newNotifications ?? false),
    )

  return queryResult
}

export type UnifiedRenewalNotification = Extract<
  NotificationCard,
  { type: NotificationType.RENEWAL | NotificationType.CONTRACT }
>

export function useHandleInboxCard() {
  const { open: openReservation } = useReservationPopup()
  const { $gtm } = useNuxtApp()
  const { locale } = useI18n()
  const notificationData = ref<UnifiedRenewalNotification>()

  function open(data: UnifiedRenewalNotification) {
    notificationData.value = data
  }

  return {
    close() {
      notificationData.value = undefined
    },
    notificationData,
    handleInboxCard(notification: NotificationCard) {
      if (notification.type === NotificationType.RESERVATION) {
        return openReservation(notification.reservation.resNo)
      }

      open(notification)

      if (notification.type === NotificationType.RENEWAL) {
        $gtm.trackEvent({
          event: 'contract_prices',
          action: 'open',
          label:
            notification.renewal.processType ===
            RenewalNotificationProcessType.REQUEST
              ? 'Available contract prices'
              : 'Live contract prices',
          category: undefined,
          component: 'contract-prices',
          position: 'inbox',
          language: unref(locale),
          _clear: true,
        })
      }
    },
  }
}
