import { useNuxtApp } from '#app'
import { useQueryClient } from '@tanstack/vue-query'
import { defineNuxtPlugin } from '#imports'

/**
 * Integration Authentication events into application flow
 * Tracking and Preloading data
 */

export default defineNuxtPlugin({
  enforce: 'post',
  setup() {
    const { $gtm, $auth, $unleash } = useNuxtApp()
    const queryClient = useQueryClient()

    useFetchInboxStatus({ enabled: computed(() => $auth.loggedIn) })

    // load accommodations
    callOnce(useAccommodationsQuery)

    $auth.on('login', ({ userID }) => {
      // track first step login event
      $gtm.trackEvent(firstLoginEvent(userID))
    })

    $auth.on('login-finish', (userData) => {
      // track finish login event
      $gtm.trackEvent(finishLoginEvent(userData.userID))
      // reload features
      $unleash.reload()
    })

    $auth.on('logout', async () => {
      if (import.meta.client) {
        $unleash.reload()
      }

      $gtm.trackEvent(logoutEvent())

      clearNuxtData('resources')
      clearNuxtState()

      await queryClient.cancelQueries()
      queryClient.removeQueries()
    })
  },
})

function firstLoginEvent(userID: string) {
  return {
    event: 'login_funnel_step1', // static value
    event_name: 'prelogin_owner', // static value
    status: 'success', // or 'error_log',  //to be dynamically filled if user credentials are correct or not)
    method: 'owner number', // static value
    user_id: userID, // Type: string, Dynamic value, taken from the placeholder with owner Id in the login box
  }
}

function finishLoginEvent(userID: string) {
  return {
    event: 'login_funnel_step2', // static value
    event_name: 'login', // static value
    method: 'owner number', // static value
    user_id: userID, // type: string, Dynamic value, taken from the placeholder with owner Id in the login box
  }
}

function logoutEvent() {
  return {
    event: 'logout', // static value
    user: { isLoggedIn: false },
  }
}
