<script setup lang="ts">
import { Icon } from 'webcc-ui-components'

const { locale } = useI18n()

const error = useError()
const hasNotFoundPageError = computed(() => {
  return error.value?.statusCode === 404 ? false : !!error.value?.message
})

const externalLinks = computed(() => ({
  privacy: `https://www.interhome.group/${locale.value}/privacy-policy`,
  legal: `https://www.interhome.group/${locale.value}/legal-information`,
}))
</script>

<template>
  <footer class="font-normal">
    <section class="flex justify-center bg-bgr-200">
      <div
        class="w-full max-w-[1272px] py-6 px-4 sm:py-4 sm:px-6 lg:py-6 lg:px-8 xl:px-8 2xl:px-0"
      >
        <ul
          class="my-0 mx-0 list-none text-xs font-normal not-italic leading-6 text-txt-200 sm:ml-0 sm:flex sm:list-disc sm:leading-4"
        >
          <li class="list-none sm:mr-2">
            <a
              class="link-peripheral"
              target="_blank"
              :href="externalLinks.privacy"
            >
              {{ $t('components.myFooter.privacyPolicy') }}
            </a>
          </li>
          <li class="sm:mx-4">
            <a
              class="link-peripheral"
              target="_blank"
              :href="externalLinks.legal"
            >
              {{ $t('components.myFooter.legalInfo') }}
            </a>
          </li>

          <li v-if="!hasNotFoundPageError" class="cursor-pointer sm:ml-2">
            <NuxtLink
              class="link-peripheral"
              tabindex="0"
              @click="$consent.openBanner"
              @keyup.enter="$consent.openBanner"
            >
              {{ $t('components.myFooter.cookieSettings') }}
            </NuxtLink>
          </li>
        </ul>

        <BasicDivider class="my-4 mx-0 bg-bgr-300" />

        <div class="leading-4 lg:flex lg:items-center lg:justify-between">
          <span
            class="my-4 mx-0 text-xs font-normal not-italic text-txt lg:my-0"
          >
            {{ $t('components.myFooter.hhdInfo') }}
          </span>

          <div
            class="mt-4 flex w-fit flex-col items-center sm:flex-row sm:items-center lg:mt-0"
          >
            <Icon
              size="sm"
              graphic="migros-logo"
              class="my-0 mx-0 w-[85px] object-scale-down md:my-0"
            />
            <Icon
              size="lg"
              graphic="hotelplan-logo"
              class="my-0 mx-0 mt-4 w-24 object-scale-down sm:mt-0 sm:ml-4 md:my-0 md:ml-4"
            />
          </div>
        </div>
      </div>
    </section>

    <div class="flex justify-center bg-white">
      <div
        class="flex w-full max-w-[1272px] items-center justify-between p-4 sm:px-6 lg:px-8 xl:px-8 2xl:px-0"
      >
        <span class="text-xs">
          {{
            $t('components.myFooter.hhdAG', {
              currentyear: new Date().getFullYear(),
            })
          }}
        </span>

        <Icon
          graphic="interhome-group-logo"
          size="custom"
          class="w-28 h-7 object-contain"
          alt="Interhome Group Logo"
        />
      </div>
    </div>
  </footer>
</template>
