<script setup lang="ts">
import { IconBtn } from 'webcc-ui-components'

const sustainabilityUrlsMap: Record<string, string> = {
  cs: 'https://www.interhome.cz/o-interhome/udrzitelnost/',
  de: 'https://www.interhome.de/ueber-interhome/nachhaltigkeit/',
  en: 'https://www.interhome.com/aboutus/sustainability',
  es: 'https://www.interhome.es/sobre-interhome/sostenibilidad/',
  fr: 'https://www.interhome.fr/a-propos-d-interhome/durabilite/',
  hr: 'https://www.interhome.hr/o-interhomeu/odrzivost/',
  it: 'https://www.interhome.it/chi-siamo/sostenibilita/',
  nl: 'https://www.interhome.nl/over-interhome/duurzaamheid/',
  pt: 'https://www.interhome.pt/sobre-a-interhome/sustentabilidade/',
  pl: 'https://www.interhome.pl/o-nas/zrownowazony-rozwoj/',
}
const instagramUrlsMap: Record<string, string> = {
  de: 'https://www.instagram.com/interhome/',
  en: 'https://www.instagram.com/interhome/',
  it: 'https://www.instagram.com/interhome/',
  pt: 'https://www.instagram.com/interhome/',
  hr: 'https://www.instagram.com/interhome/',
  cs: 'https://www.instagram.com/interhome/',
  fr: 'https://www.instagram.com/interhome_fr/',
  es: 'https://www.instagram.com/interhome_es/',
  nl: 'https://www.instagram.com/interhome.vakantiewoningen/',
  pl: 'https://www.instagram.com/interhome_polska/',
}
const facebookUrlsMap: Record<string, string> = {
  de: 'https://www.facebook.com/InterhomeCH',
  en: 'https://www.facebook.com/interhome',
  it: 'https://www.facebook.com/interhome',
  pt: 'https://www.facebook.com/interhome',
  hr: 'https://www.facebook.com/interhome',
  fr: 'https://www.facebook.com/interhomefr',
  es: 'https://www.facebook.com/InterhomeES',
  nl: 'https://www.facebook.com/InterhomeNL/',
  cs: 'https://www.facebook.com/interhomeCZ',
  pl: 'https://www.facebook.com/InterhomePL/',
}
const INTERHOME_LINKEDIN_URL: string =
  'https://www.linkedin.com/company/interhomegroup/'
const INTERHOME_GROUP_URL = 'https://www.interhome.group'

const { t, locale } = useI18n()
const { $ROUTE } = useNuxtApp()

const supportCenter = computed(() =>
  [
    {
      text: t('components.common.contactList'),
      link: $ROUTE.CONTACT_LIST,
    },
    {
      text: t('components.common.contactUs'),
      link: $ROUTE.CONTACT_US,
    },
  ].filter(Boolean),
)

const aboutInterhome = computed(() => [
  {
    text: t('components.preFooter.interhome'),
    link: `${INTERHOME_GROUP_URL}/${locale.value}`,
  },
  {
    text: t('components.preFooter.sustainability'),
    link: sustainabilityUrlsMap[locale.value],
  },
])

function openFacebook() {
  window.open(facebookUrlsMap[locale.value], '_blank')
}

function openInstagram() {
  window.open(instagramUrlsMap[locale.value], '_blank')
}

function openLinkedin() {
  window.open(INTERHOME_LINKEDIN_URL, '_blank')
}
</script>

<template>
  <section class="flex justify-center bg-bgr-100" data-id="footer">
    <div
      class="w-full max-w-[1272px] px-4 py-6 sm:px-6 md:flex md:justify-between lg:px-8 xl:px-8 2xl:px-0"
    >
      <div class="mt-4">
        <p class="text-sm font-medium leading-4.5 text-txt">
          {{ $t('components.common.supportCenter') }}
        </p>
        <ul class="m-4 text-xs font-normal leading-6 text-txt-200">
          <li v-for="(support, index) in supportCenter" :key="index">
            <NuxtLink class="link-peripheral" :to="support.link">
              {{ support.text }}
            </NuxtLink>
          </li>
        </ul>
      </div>

      <div class="mt-4">
        <p class="text-sm font-medium leading-4.5 text-txt">
          {{ $t('components.preFooter.aboutIhGroup') }}
        </p>
        <ul class="m-4 text-xs font-normal leading-6 text-txt-200">
          <li v-for="about in aboutInterhome" :key="about.text">
            <a class="link-peripheral" target="_blank" :href="about.link">
              {{ about.text }}
            </a>
          </li>
        </ul>
      </div>

      <div class="mt-4">
        <p class="text-sm font-medium leading-4.5 text-txt">
          {{ $t('components.preFooter.followGroup') }}
        </p>
        <div class="mt-4 flex gap-4">
          <IconBtn icon="facebook" @click.prevent="openFacebook" />
          <IconBtn icon="instagram" @click.prevent="openInstagram" />
          <IconBtn icon="linkedin" @click.prevent="openLinkedin" />
        </div>
      </div>
    </div>
  </section>
</template>
