import { createApp, h } from 'vue'
import { Toast as ToastComponent } from 'webcc-ui-components'
import { defineNuxtPlugin } from '#imports'

const TOASTS = new Map()

export default defineNuxtPlugin({
  name: 'toast:plugin',
  setup() {
    function toast(props: Record<string, unknown>) {
      if (TOASTS.has(props.id)) return

      const { text, ...rest } = props
      const toastProps = {
        fixed: false,
        ...rest,
        onClose,
      }
      const toastApp = createApp({
        render: () => h(ToastComponent, toastProps, () => text),
      })

      const toastContainer = document.createElement('div')
      toastContainer.setAttribute('id', `toast-${props.id}`)
      if (props.class) {
        toastContainer.classList.add('class', props.class)
      }

      document.body.appendChild(toastContainer)

      toastApp.mount(`#toast-${props.id}`)
      TOASTS.set(props.id, toastApp)

      function onClose() {
        ;((id) => {
          setTimeout(() => {
            TOASTS.get(id).unmount()
            TOASTS.delete(id)
            document.getElementById(`toast-${props.id}`)?.remove()
          })
        })(props.id)
      }
    }

    return {
      provide: {
        toast,
      },
    }
  },
})

declare module '#app' {
  interface NuxtApp {
    $toast(props: Record<string, unknown>): void
  }
}
