<script setup lang="ts">
const { $consent } = useNuxtApp()

useDynamicBodyClass(
  computed(() => $consent.consentOpened),
  'consent-popup-overflow-hidden',
)
</script>

<template>
  <div id="consent" class="relative" data-id="consent-container">
    <ConsentBanner
      v-if="$consent.consentOpened"
      @save-choice="$consent.saveChoice"
      @open-config="$consent.openConfig"
    />
    <ConsentConfig
      v-if="$consent.configOpened"
      :statistics="$consent.decision.statistics"
      :other="$consent.decision.other"
      @close="$consent.configOpened = false"
      @save-choice="$consent.saveChoice"
    />
  </div>
</template>

<style scoped>
:global(.consent-popup-overflow-hidden) {
  overflow: hidden;
}
</style>
