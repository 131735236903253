import { defineNuxtPlugin } from 'nuxt/app'

const REMEMBER_ME_ID = 'RMSPPLID' as const

interface RememberMe {
  set(userID: string, remember: boolean): void
  get(): string
  has(): boolean
  clear(): void
}

export default defineNuxtPlugin({
  name: 'remember-me',
  enforce: 'post',
  setup(nuxtApp) {
    const $rememberMe: RememberMe = readonly({
      set(userId, remember) {
        if (import.meta.server) return
        if (remember) {
          localStorage.setItem(REMEMBER_ME_ID, userId)
        } else {
          localStorage.removeItem(REMEMBER_ME_ID)
        }
      },
      has() {
        if (import.meta.server) return false
        return typeof localStorage.getItem(REMEMBER_ME_ID) === 'string'
      },
      get() {
        if (import.meta.server) return ''
        return localStorage.getItem(REMEMBER_ME_ID) ?? ''
      },
      clear() {
        if (import.meta.server) return
        localStorage.removeItem(REMEMBER_ME_ID)
      },
    })

    nuxtApp.provide('rememberMe', $rememberMe)
    Object.defineProperty(nuxtApp.vueApp, '$rememberMe', {
      get: () => $rememberMe,
    })
  },
})

declare module '#app' {
  interface NuxtApp {
    $rememberMe: RememberMe
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $rememberMe: RememberMe
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $rememberMe: RememberMe
  }
}
