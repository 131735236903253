<script setup lang="ts">
import { throttle } from 'lodash-es'
import { IconBtn } from 'webcc-ui-components'
import { vOnClickOutside } from '@vueuse/components'

defineOptions({ inheritAttrs: false })

withDefaults(
  defineProps<{
    title?: string
    activatorClass?: string
    portal?: string
    order?: number
    open?: boolean
    hideHeader?: boolean
    fullHeight?: boolean
  }>(),
  {
    title: undefined,
    activatorClass: undefined,
    portal: 'body',
    order: 100,
    open: false,
    hideHeader: false,
    fullHeight: false,
  },
)

const emit = defineEmits<{
  (e: 'close'): void
}>()

const floatingRef = ref<HTMLDivElement>()
const mainRef = ref<HTMLDivElement>()
const headerRef = ref<HTMLDivElement>()

const startX = ref(0)
const startY = ref(0)

let touchMove = false
let closingTimeoutID: ReturnType<typeof setTimeout>

const { $touch, $device } = useNuxtApp()

const onSlidingThrottle = throttle(onSliding, 50)

const { arrivedState } = useScroll(mainRef)

const touchBehaviour = computed(() => $touch?.supports || isMobile.value)
const isMobile = computed(() => $device.isAndroid || $device.isIOS)

function closePanel() {
  emit('close')
}

/**
 * Method which prevents a function from running if it has run “recently”
 */
function onSliding(shift: number) {
  if (!touchMove || !floatingRef.value) return
  floatingRef.value.style.transform = `translateY(${shift}px)`
}

/**
 * Method which handles @touchstart event
 * @param event
 */
function startDrag(event: TouchEvent) {
  if (!touchBehaviour.value || event.touches.length > 1) return
  startX.value = event.touches[0].clientX
  startY.value = event.touches[0].clientY
}

/**
 * Method which handles @touchmove event
 * @param event
 */
function moveDrag(event: TouchEvent) {
  const shiftX = startX.value - event.touches[0].clientX
  const shiftY = startY.value - event.touches[0].clientY

  if (Math.abs(shiftY) > Math.abs(shiftX)) {
    if (shiftY < 0) {
      touchMove = true
      onSlidingThrottle(-shiftY)
    }
  }
}

/**
 * Method which handles @touchend event
 */
function endDrag() {
  if (touchMove) {
    touchMove = false
    slideDown()
  }
}

function slideDown() {
  if (!floatingRef.value) return
  floatingRef.value.style.transform = `translateY(100%)`
  closingTimeoutID = setTimeout(closePanel, 300)
}

onBeforeUnmount(() => {
  onSlidingThrottle?.cancel()
  if (closingTimeoutID) clearTimeout(closingTimeoutID)
})
</script>

<template>
  <Teleport :to="portal" :order="order">
    <Transition name="fade-moveup">
      <div
        v-if="open"
        class="fixed inset-0 z-50 bg-transparent"
        v-bind="$attrs"
        data-modal
      >
        <div
          v-bind="$attrs"
          ref="floatingRef"
          v-on-click-outside="[closePanel, { ignore: [floatingRef] }]"
          class="panel-safe-area wrapper fixed bottom-0 left-0 z-50 flex w-full flex-col rounded-t-xl border border-bgr-300 bg-bgr duration-200 min-h-[40%]"
          :class="{
            'max-h-full min-h-full': fullHeight,
            'max-h-[85%]': !fullHeight,
          }"
          @click.stop
        >
          <header
            v-if="!hideHeader"
            ref="headerRef"
            class="flex max-h-14 items-center justify-end border-b border-bgr-200 px-6 py-4"
            :class="{ 'shadow-lg': !arrivedState.top }"
            @touchstart="startDrag"
            @touchmove="moveDrag"
            @touchend="endDrag"
          >
            <slot name="header-left" />

            <p class="flex grow justify-center text-base font-medium leading-5">
              {{ title }}
            </p>

            <IconBtn
              class="close-button"
              tabindex="0"
              variant="transparent"
              icon="arrow-down"
              @click.prevent="slideDown"
            />
          </header>

          <main ref="mainRef" class="h-full overflow-y-scroll">
            <slot />
          </main>

          <footer v-if="$slots.footer">
            <slot name="footer" />
          </footer>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.fade-moveup-enter-active,
.fade-moveup-leave-active {
  transition: transform 0.2s ease-out;
}

.fade-moveup-enter,
.fade-moveup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.wrapper {
  filter: drop-shadow(0px -35px 50px rgba(21, 21, 21, 0.25));
}

:deep(.close-button) svg {
  @apply text-txt-400;
}
</style>
