export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hooks.hookOnce('app:suspense:resolve', () => {
    window.isHydrated = true
  })
})

declare global {
  interface Window {
    isHydrated?: boolean
  }
}
