import { reactive } from 'vue'
import { useRequestFetch, useRuntimeConfig } from '#app'
import { WEB_PUSH_PREFIX } from '~/constants'

export default defineNuxtPlugin({
  name: 'webpush:plugin',
  setup(nuxtApp) {
    const runtimeConfig = useRuntimeConfig()
    const requestFetch = useRequestFetch()

    const $webpush = reactive({
      subscribed: false,
      supported: false,
      granted: false,
      subscribe,
      unsubscribe,
    })

    if (import.meta.server) return

    const vapidPublicKey = runtimeConfig.public.webPush.vapidPublicKey

    nuxtApp.provide('webpush', $webpush)
    ;(async function () {
      if (!('serviceWorker' in navigator)) return

      navigator.serviceWorker.ready.then((registration) => {
        registration.active.onerror = (error) => {
          console.error('An error occurred in the service worker', error)
        }
      })

      if (vapidPublicKey && 'PushManager' in window) $webpush.supported = true

      try {
        if (!$webpush.supported) return

        const registration = await establishRegistration()

        $webpush.subscribed = Boolean(
          await registration.pushManager.getSubscription(),
        )

        $webpush.granted = Notification.permission === 'granted'

        if (
          $webpush.supported &&
          $webpush.granted &&
          nuxtApp.$auth?.loggedIn &&
          parseLocalStorageRecord(nuxtApp.$auth?.user?.userID).subscribed
        ) {
          await subscribe()
        }

        nuxtApp.$auth.on('login-finish', async ({ userID }) => {
          if (
            $webpush.supported &&
            $webpush.granted &&
            parseLocalStorageRecord(userID).subscribed
          ) {
            await subscribe()
          }
        })
      } catch (e) {
        console.error(e)
      }
    })()

    async function requestPermission() {
      if (!$webpush.supported) return false

      const result = await Notification.requestPermission()

      if (result === 'denied') {
        $webpush.granted = false
        console.error('The user explicitly denied the permission request.')
        return
      }

      if (result === 'granted') {
        $webpush.granted = true
        console.info('The user accepted the permission request.')
      }

      return $webpush.granted
    }

    async function subscribe() {
      if (!$webpush.supported) return false

      if (!(await requestPermission())) return false

      const registration = await establishRegistration()

      if (!registration) {
        console.error('No service worker registration to subscribe')
        return false
      }

      const subscription = await establishSubscription(registration)

      // Send push notification
      await requestFetch('/api/webpush/subscribe', {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: { 'content-type': 'application/json' },
      })

      return true
    }

    async function unsubscribe() {
      if (!$webpush.supported) return false

      const registration = await establishRegistration()
      const subscription = await registration.pushManager.getSubscription()

      const [success] = await Promise.all([
        subscription.unsubscribe(),
        requestFetch('/api/webpush/unsubscribe', {
          method: 'POST',
          body: JSON.stringify({ endpoint: subscription.endpoint }),
          headers: { 'Content-Type': 'application/json' },
        }),
      ])

      if (success) {
        $webpush.subscribed = false
        return true
      }

      return false
    }

    async function establishRegistration() {
      const registration = await navigator.serviceWorker.ready

      if (registration.active) return registration

      return await new Promise((resolve) => {
        const worker =
          registration.installing || registration.waiting || registration.active

        worker.addEventListener('statechange', (event) => {
          event.target.state === 'activated' && resolve(registration)
        })
      })
    }

    async function establishSubscription(registration) {
      let subscription = await registration.pushManager.getSubscription()

      if (subscription) {
        console.info(
          'User is already subscribed. Using existing subscription object.',
        )
      } else {
        // register push client
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          // public vapid key
          applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
        })
      }

      if (subscription) $webpush.subscribed = true

      return subscription
    }
  },
})

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i)
    outputArray[i] = rawData.charCodeAt(i)

  return outputArray
}

function parseLocalStorageRecord(userID) {
  return JSON.parse(localStorage.getItem(WEB_PUSH_PREFIX + userID) ?? '{}')
}
