export function useErv() {
  const config = useRuntimeConfig()
  const { locale } = useI18n()

  return {
    open() {
      if (!config.public.contactInsurance) return
      const url = config.public.contactInsurance.replace(
        /{(\w*)}/g,
        locale.value,
      )
      window.open(url, '_blank')
    },
  }
}
