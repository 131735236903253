import { h } from 'vue'
import { defineNuxtPlugin } from '#app'
import { renderToString } from 'vue/server-renderer'
import { LoadingSpinner } from 'webcc-ui-components'
import PullToRefresh from 'pulltorefreshjs'
import { isStandaloneApplication } from '~/utils'

export default defineNuxtPlugin({
  name: 'pwa/pull-to-refresh',
  dependsOn: ['device-plugin'],
  hooks: {
    'app:mounted': (app) => {
      if (!isStandaloneApplication()) return
      const isApplePortableDevices = computed(
        () =>
          app._context.app.$device?.isIOS || app._context.app.$device?.isIPad,
      )

      whenever(
        isApplePortableDevices,
        async () => {
          const LoadingSpinnerPull = await renderToString(
            h(LoadingSpinner, {
              spin: false,
              showProgressBar: false,
              class: 'py-2',
            }),
          )
          const LoadingSpinnerRefresh = await renderToString(
            h(LoadingSpinner, { class: 'py-2' }),
          )

          PullToRefresh.init({
            mainElement: 'body',
            onRefresh() {
              window.location.reload()
            },
            distReload: 75,
            iconArrow: LoadingSpinnerPull,
            iconRefreshing: LoadingSpinnerRefresh,
            getMarkup: () => `
            <div class="__PREFIX__box">
              <div class="__PREFIX__content">
                <div class="__PREFIX__icon"></div>
              </div>
            </div>
          `,
          })
        },
        { immediate: true, once: true },
      )
    },
  },
})
