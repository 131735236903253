import type { ServerResponse } from 'node:http'
import type { NuxtSSRContext, NuxtApp } from '#app'
import {
  defineNuxtPlugin,
  useRequestHeaders,
  useState,
  onNuxtReady,
} from '#app'
import { get, debounce } from 'lodash-es'
import { reactive } from '#imports'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

// Nuxt.js supports lodash/template for plugins loaded in modules
// Device module provides breakpoints coming from `nuxt.config.js` in most cases
const BREAKPOINTS_MAP: Record<Breakpoint, [number, number]> = JSON.parse(
  '{"xs":[1,575],"sm":[576,767],"md":[768,991],"lg":[992,1199],"xl":[1200,1399],"2xl":[1400,9999]}',
)

export default defineNuxtPlugin({
  name: 'device-plugin',
  enforce: 'pre',
  setup(nuxtApp) {
    if (import.meta.server) {
      const res = get(nuxtApp, 'ssrContext.event.node.res') as ServerResponse
      if (res && !res.headersSent) {
        res.setHeader('Accept-CH', 'Viewport-Width, Width')
      }
    }

    const { 'viewport-width': viewportWidth = '0' } = useRequestHeaders()
    const width = useState<number>('serverWidth', () =>
      parseInt(viewportWidth, 10),
    )

    // In order to re-render components on changing device context properties
    // It needs to wrap object in Observable wrapper
    const $device: NuxtApp['$device'] = reactive({
      width: width.value,
      height: 0,
      current: getCurrent(width.value),
      isIOS: false,
      isIPad: false,
      isAndroid: false,
      isLandscape: false,
      isMobileLandscape: false,
      parseRule,
      test(rule) {
        const parseResult = parseRule(rule)
        if (!parseResult) return true
        const [mode, breakpoint] = parseResult
        const { [breakpoint]: breakpointWidth, width } = $device
        if (width === 0) return true
        return (
          (mode === '<' && width < breakpointWidth) ||
          (mode === '>=' && width >= breakpointWidth)
        )
      },
      ...(Object.fromEntries(
        Object.entries(BREAKPOINTS_MAP).map(([name, [start]]) => [name, start]),
      ) as Record<Breakpoint, number>),
    } satisfies NuxtApp['$device'])

    Object.defineProperty(nuxtApp, '$device', { get: () => $device })
    Object.defineProperty(nuxtApp.vueApp, '$device', { get: () => $device })
    Object.defineProperty(nuxtApp.vueApp.config.globalProperties, '$device', {
      get: () => $device,
    })

    function updateDeviceSize(
      { width, height }: { width: number; height: number },
      initial = false,
    ) {
      $device.width = width
      $device.height = height
      $device.current = getCurrent(width)
      const agent = getAgentFromContext(nuxtApp)
      $device.isIOS = isIOSAgent(agent)
      // it is impossible to detect iPad on server in 99%
      // for initial device detection it is better to set false
      // to prevent hydration issues
      $device.isIPad = initial ? false : isIPadAgent(agent)
      $device.isLandscape = initial ? false : isLandscape()
      $device.isMobileLandscape = $device.isLandscape && height < $device.sm
      $device.isAndroid = isAndroidAgent(agent)
    }

    if (import.meta.client) {
      onNuxtReady(() => {
        updateDeviceSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      })

      if (
        'removeDevicePluginListener' in window &&
        typeof window.removeDevicePluginListener === 'function'
      ) {
        window.removeDevicePluginListener()
      }

      const devicePluginResizeHandler = debounce(() => {
        updateDeviceSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }, 150)

      // On window resize it should re-set device properties
      window.addEventListener('resize', devicePluginResizeHandler)

      Object.assign(window, {
        removeDevicePluginListener() {
          window.removeEventListener('resize', devicePluginResizeHandler)
        },
      })
    }
  },
})

export function isIPadAgent(agent: string) {
  if (import.meta.client) {
    return (
      /ipad/i.test(navigator.userAgent) ||
      // Check for iPad that has the same UserAgent as Desktop Safari
      (/macintosh/i.test(agent) && navigator.maxTouchPoints > 1)
    )
  } else {
    return /ipad/i.test(agent)
  }
}

export function isIOSAgent(agent: string) {
  return /iphone|ipad|ipod|ios/i.test(agent) || isIPadAgent(agent)
}

export function isAndroidAgent(agent: string) {
  return /android/i.test(agent)
}

export function isLandscape() {
  return (
    import.meta.client && window.matchMedia('(orientation: landscape)').matches
  )
}

export function getAgentFromContext<T extends { ssrContext?: NuxtSSRContext }>(
  nuxtApp: T,
): string {
  if (import.meta.server) {
    return get(
      nuxtApp,
      'ssrContext.event.node.req.headers.user-agent',
      '',
    ) as string
  } else {
    return navigator.userAgent
  }
}

export function getCurrent(width: number) {
  return (Object.entries(BREAKPOINTS_MAP).find(([_, [from, to]]) => {
    return width >= from && width <= to
  })?.[0] || '') as Breakpoint
}

const RULE_PROP_REGEX = /^(<|>=)(xs|sm|md|lg|xl|2xl)$/

export function parseRule(rule: string) {
  const matches = RULE_PROP_REGEX.exec(rule)
  if (!matches) return null
  const objectResult = {
    mode: matches[1],
    breakpoint: matches[2] as Breakpoint,
  }
  const tupleResult = [objectResult.mode, objectResult.breakpoint] as const
  return Object.assign(tupleResult, objectResult)
}
