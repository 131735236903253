import isEmpty from 'lodash-es/isEmpty'
import get from 'lodash-es/get'
import { watch } from 'vue'
import { type NuxtApp, useNuxtApp } from '#app'
import type { RouteLocationNormalized } from 'vue-router'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

const GTM_LIBRARY_BASE_URL = 'https://www.googletagmanager.com/gtm.js'
const pagePool = 'ownerportal' as const

type GTMContext = {
  trackEvent<P extends Record<string, unknown>>(params: P): void
}

const trackEvent = (params: Record<string, unknown>) => {
  try {
    window.dataLayer?.push(params)
  } catch (_e) {
    // ignore error
  }
}

export default defineNuxtPlugin({
  setup() {
    const nuxtApp = useNuxtApp()

    if (import.meta.server) {
      nuxtApp.provide('gtm', {
        trackEvent(_params: Record<string, unknown>) {},
      })
      return
    }

    const { $auth, $router, $i18n } = nuxtApp

    if (!window.dataLayer) {
      window.dataLayer = []
    }

    trackEvent({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })

    trackEvent({
      meta: {
        version: __VERSION__,
      },
      page: getPageObjectFromContext(nuxtApp),
      user: {
        isLoggedIn: $auth.loggedIn,
        ...($auth.loggedIn
          ? {
              properties: $auth.profile?.tracking?.properties,
              category: $auth.profile?.tracking?.loginType,
            }
          : null),
      },
    })

    $router.afterEach((to, from) => {
      // ignore initial page load, it should be stored on SSR
      if (from.fullPath === to.fullPath) return

      sendPageChangeEvent(nuxtApp, to)
    })

    watch(
      () => $i18n.locale.value,
      () => sendPageChangeEvent(nuxtApp, $router.currentRoute.value),
    )

    nuxtApp.provide('gtm', {
      trackEvent,
    })
  },
  hooks: {
    'app:beforeMount'() {
      addGTMLibrary()
    },
  },
})

function getPageObjectFromContext(nuxtApp: NuxtApp) {
  const {
    $router: {
      currentRoute: {
        value: { path, query, name },
      },
    },
  } = nuxtApp

  const language = get(
    nuxtApp,
    '$i18n.locale.value',
    get(nuxtApp, '$i18n.defaultLocale.value', 'en'),
  )

  return {
    language,
    path,
    query: isEmpty(query)
      ? null
      : new URLSearchParams(query as Record<string, string>).toString(),
    title: (typeof document !== 'undefined' && document.title) || '',
    type: name,
    pagePool,
  }
}

function sendPageChangeEvent(nuxtApp: NuxtApp, to: RouteLocationNormalized) {
  const {
    $router: {
      currentRoute: {
        value: { query },
      },
    },
  } = nuxtApp
  const metaTitle = to.meta.title as undefined | string

  const language = get(
    nuxtApp,
    '$i18n.locale.value',
    get(nuxtApp, '$i18n.defaultLocale.value', 'en'),
  )

  nuxtApp.$gtm.trackEvent({
    page: {
      language,
      path: to.path,
      query: isEmpty(query)
        ? null
        : new URLSearchParams(query as Record<string, string>).toString(),
      title: metaTitle ? nuxtApp.$i18n.t(metaTitle) : '',
      type: to.name || 'default',
      pagePool,
    },
    event: '🚀 pageview',
    user: {
      isLoggedIn: nuxtApp.$auth.loggedIn,
      properties: nuxtApp.$auth.profile?.tracking?.properties,
      category: nuxtApp.$auth.profile?.tracking?.loginType,
    },
  })
}

function addGTMLibrary() {
  const runtimeConfig = useRuntimeConfig()
  const gtmParams = runtimeConfig.public.gtm?.params || ''
  const script: HTMLScriptElement = document.createElement('script')
  script.async = false
  script.defer = true
  script.type = 'text/javascript'
  script.src = `${GTM_LIBRARY_BASE_URL}?${gtmParams}`
  document.head.appendChild(script)
}

declare module '#app' {
  interface NuxtApp {
    $gtm: GTMContext
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $gtm: GTMContext
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $gtm: GTMContext
  }
}

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}
