import { get } from 'lodash-es'
import { defineNuxtPlugin, useRouter, useNuxtApp } from '#imports'

export default defineNuxtPlugin({
  async setup(nuxtApp) {
    if (import.meta.server) return

    // const [hasNotification, setHasNotification] = useState(() => false)

    const $auth = useNuxtApp().$auth || get(nuxtApp, '$auth')
    const router = useRouter()

    // Register sse store to communicate between plugin and component
    const isNotificationIconVisible = useState(
      'isNotificationIconVisible',
      () => false,
    )

    await router.isReady()

    const RECONNECT_TIMEOUT_SECONDS = 5000
    const OPEN = 1
    const CLOSED = 2

    let eventSource: EventSource | null = null
    let reconnectId: number | null = null

    /**
     * This function we need to use to reconnect to the server
     */
    const reconnect = () => {
      reconnectId = setTimeout(
        connect,
        RECONNECT_TIMEOUT_SECONDS,
      ) as unknown as number
    }

    /**
     * This function we need to use to reset timeout
     */
    const resetTimeout = () => {
      reconnectId && clearTimeout(reconnectId)
    }

    /**
     * This function we need to use to create connection between FE and BE using EventSource
     */
    const connect = () => {
      eventSource = new EventSource('/api/events')

      eventSource.addEventListener('notification', () => {
        isNotificationIconVisible.value = true
      })

      eventSource.addEventListener('error', () => {
        eventSource!.close()
        resetTimeout()

        if ($auth.loggedIn) reconnect()
      })
    }

    // If supplier already loggedin - connect
    $auth.loggedIn && connect()

    router.afterEach(() => {
      // Event source connection already opened and user already loggout
      // We need to close ES and reset reconnectId
      if (!$auth.loggedIn && eventSource?.readyState === OPEN) {
        eventSource.close()
        resetTimeout()
        return
      }

      // Event source is closed or ES is null and user loggedin
      // We need to reset reconnectId and try to connect
      if (
        $auth.loggedIn &&
        (eventSource?.readyState === CLOSED || eventSource === null)
      ) {
        resetTimeout()
        connect()
      }
    })
  },
})
