import { defineNuxtPlugin } from '#imports'
import { TrackProfileEventType } from '~/constants/enums'

type TrackEvents = {
  trackEditProfile(param: string): void
  trackBackToOldOwnerPortal(): void
}

export const PaymentEventLabelMap: { [key: string]: string } = {
  [TrackProfileEventType.IBAN]: 'Bank account details',
  [TrackProfileEventType.PaymentSchedule]: 'Payment Schedule',
}

export default defineNuxtPlugin({
  name: 'tracking',
  enforce: 'post',
  setup(nuxtApp) {
    if (import.meta.server) return
    const { $consent, $gtm } = nuxtApp

    const { statistics: consentStatistics, other: consentRetargeting } =
      $consent.decision

    $gtm.trackEvent({
      // NO event should be pushed here!! event: "user consent",
      consentStatistics,
      consentRetargeting,
    })

    watch(
      $consent.decision,
      (decision) => {
        $gtm.trackEvent({
          event: 'user consent',
          consentStatistics: decision.statistics,
          consentRetargeting: decision.other,
        })
      },
      {
        // does not trigger callback on initial call
        immediate: false,
      },
    )

    nuxtApp.provide('tracking', getTrackEvents(nuxtApp))
  },
})

function getTrackEvents(nuxtApp: object): object {
  const { $gtm, $i18n } = nuxtApp
  return {
    trackEditProfile: (section: TrackProfileEventType) => {
      $gtm.trackEvent({
        event: 'edit_profile',
        action: 'edit',
        label: PaymentEventLabelMap[section],
        category: 'Payment info',
        component: 'profile',
        position: undefined,
        language: unref($i18n.locale),
        _clear: true,
      })
    },
    trackBackToOldOwnerPortal: () => {
      $gtm.trackEvent({
        event: 'back_to_old_portal',
        action: 'navigate',
        label: 'Back to old portal',
        category: undefined,
        component: 'link',
        position: undefined,
        language: unref($i18n.locale),
        _clear: true,
      })
    },
  }
}

declare module '#app' {
  interface NuxtApp {
    $tracking: TrackEvents
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $tracking: TrackEvents
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $tracking: TrackEvents
  }
}
