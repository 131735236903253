<script setup lang="ts">
import { IconBtn } from 'webcc-ui-components'

const [isOpen, togglePanel] = useToggle()
</script>

<template>
  <div class="flex support-center-button">
    <IconBtn
      data-id="support-center-button-mobile"
      icon="chat-question"
      variant="neutral"
      size="md"
      :is-active="isOpen"
      @click="togglePanel"
    />

    <BasicPanel
      :title="$t('components.common.supportCenter')"
      :open="isOpen"
      @close="isOpen = false"
    >
      <SupportCenterMenu
        class="grid gap-y-2 px-10 py-8 md:min-w-[375px]"
        @click="isOpen = false"
      />
    </BasicPanel>
  </div>
</template>
