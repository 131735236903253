<script setup lang="ts">
import { GeneralIcons, BrandIcon } from 'webcc-ui-components'

const route = useRouter()
const { t } = useI18n()
const title = computed(() => {
  const title = route.currentRoute.value.meta?.title
  return title ? t(title as string) : ''
})

useHead({ title })
</script>

<template>
  <div>
    <VitePwaManifest />
    <NuxtLoadingIndicator :color="'#0096db'" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <GeneralIcons />
    <BrandIcon />
  </div>
</template>
