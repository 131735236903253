<script setup lang="ts">
import { IconBtn } from 'webcc-ui-components'
import { sortBy } from 'lodash-es'

const emit = defineEmits<{
  (e: 'click', opened: boolean): void
}>()

const { locales, locale: currentLocale } = useI18n()
const [opened, toggle] = useToggle()

const sortedLocales = computed(() =>
  sortBy(unref(locales) as { code: string; name: string }[], 'code'),
)

function toggleLanguage() {
  toggle()
  emit('click', opened.value)
}
</script>

<template>
  <div>
    <IconBtn
      size="md"
      variant="neutral"
      :is-active="opened"
      :initials="currentLocale"
      @click="toggleLanguage"
    />

    <LazyLanguageModal
      v-if="opened"
      class="max-w-80"
      :title="$t('components.common.language')"
      @click="toggleLanguage"
    >
      <LanguageButtonList
        :locales="sortedLocales"
        :locale="currentLocale"
        @click="toggleLanguage"
      />
    </LazyLanguageModal>
  </div>
</template>
