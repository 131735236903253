type ObstacleOptions = {
  name: string
  height: number
}

const obstacles = ref<ObstacleOptions[]>([])

export function useObstacle() {
  function registerObstacle(
    name: string,
    height: number,
    safeAreaHeight: number = 0,
  ) {
    addObstacle(name, height, safeAreaHeight)
    return () => deleteObstacle(name)
  }

  function addObstacle(
    name: string,
    height: number,
    safeAreaHeight: number,
  ): void {
    const index = obstacles.value.findIndex((item) => item.name === name)
    if (index >= 0) obstacles.value.splice(index, 1)
    obstacles.value.push({
      name,
      height: height + safeAreaHeight,
    })
  }

  function deleteObstacle(name: string): void {
    const index = obstacles.value.findIndex((item) => item.name === name)
    obstacles.value.splice(index, 1)
  }

  const obstaclePixelHeight = computed(() => {
    const [lastObstacle] = (obstacles.value ?? []).slice(-1)
    return `${lastObstacle?.height ?? 0}px`
  })

  return {
    registerObstacle,
    obstaclePixelHeight,
  }
}
