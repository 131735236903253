<script lang="ts">
import type { SlotsType, VNode } from 'vue'
import { defineComponent } from 'vue'
// import { defineNuxtComponent } from 'nuxt/app';

const RULE_PROP_REGEX = /^(<|>=)(xs|sm|md|lg|xl|2xl)$/
const IN_CLASS_REGEX = /(^|\s)(flex|inline-flex|block|grid)($|\s)/

export default defineComponent({
  name: 'OnDevices',
  slots: Object as SlotsType<{
    default: {}
  }>,
  props: {
    // Rule on which devices to render children components
    // Rule can be only `less than` (<) OR `greater and equal than` (>=)
    // Examples: '<sm', '<2xl', '<lg', '>=md', '>=sm'
    rule: {
      type: String,
      required: true,
      validator(value: string) {
        return RULE_PROP_REGEX.test(value)
      },
    },
    // in browsers that do not support Accept-CH header
    // at first render the width equals 0
    // and as a fallback visibility classes are needed, e.g. `block` or `flex`
    showClass: { type: String, default: '' },
  },
  setup() {},
  computed: {
    mode() {
      return this.$device.parseRule(this.rule)?.mode
    },
    breakpoint() {
      return this.$device.parseRule(this.rule)?.breakpoint
    },
  },
  methods: {
    renderBasedOnWidth() {
      const breakpointWidth = this.breakpoint
        ? this.$device[this.breakpoint]
        : 0
      const toRender =
        (this.mode === '<' && this.$device.width < breakpointWidth) ||
        (this.mode === '>=' && this.$device.width >= breakpointWidth)
      const vnodes =
        toRender && this.$slots.default ? this.$slots.default({}) : null
      return vnodes
    },
    renderPatchingClassAttr() {
      if (!this.$slots.default) return null

      const vnodes = this.$slots.default({})

      const patchVNodeClass = (vnode: VNode) => {
        if (!vnode.props) {
          vnode.props = { class: '' }
        }
        if (!vnode.props.class) {
          vnode.props.class = ''
        }
        const staticClass: string = vnode.props.class
        const [, , existClass] = IN_CLASS_REGEX.exec(staticClass) || []
        const showClass = this.showClass || existClass || 'block'
        let newClass
        if (this.mode === '<')
          newClass = [!existClass && showClass, `${this.breakpoint}:hidden`]
            .filter(Boolean)
            .join(' ')
        if (this.mode === '>=')
          newClass = `hidden ${this.breakpoint}:${showClass}`
        vnode.props.class = [staticClass, newClass].filter(Boolean).join(' ')
        return vnode
      }

      return vnodes.map(patchVNodeClass)
    },
  },
  render() {
    // return this.renderPatchingClassAttr()
    return this.$device.width
      ? this.renderBasedOnWidth()
      : this.renderPatchingClassAttr()
  },
})
</script>
