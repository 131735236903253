import { useQuery } from '@tanstack/vue-query'
import type { MaybeRef } from 'vue'
import type { components, paths } from '~/schema'
import { AccommodationDetailsContractAttentionLevel } from '~/schema'

export type AccommodationID = string

export type AccommodationsResponse =
  components['schemas']['AccommodationsResponse']

export type Accommodation = components['schemas']['AccommodationDetails']

export type AccommodationsRequestQuery = NonNullable<
  paths['/api/accommodations']['get']['parameters']['query']
>

export function useAccommodationsQuery(
  params?: MaybeRef<{
    inactive?: MaybeRef<boolean>
    rewarding?: MaybeRef<boolean>
  }>,
) {
  const fetch = useApiFetch()
  const auth = useAuth()
  const rewardingParam = computed(() =>
    unref(unref(params)?.rewarding || false),
  )
  const inactiveParam = computed(() =>
    // `inactive` is always `undefined` for rewarding sub-list
    rewardingParam.value ? undefined : unref(unref(params)?.inactive || false),
  )
  return useQuery({
    enabled: computed(() => auth.loggedIn),
    queryKey: ['accommodations', rewardingParam, inactiveParam] as const,
    staleTime: 24 * 60 * 60 * 1000, // 24h stale time
    queryFn({ signal, queryKey: [_, rewarding, inactive] }) {
      return fetch<AccommodationsResponse>(
        rewarding ? '/api/accommodations/rewarding' : '/api/accommodations',
        {
          signal,
          query: {
            inactive,
          } satisfies AccommodationsRequestQuery,
        },
      )
    },
    select(data) {
      return data.content
    },
  })
}

export function isContractAttentionLevelYellow(accommodation: Accommodation) {
  return (
    AccommodationDetailsContractAttentionLevel.WARNING ===
    accommodation.contractAttentionLevel
  )
}
