import { h, reactive, render } from 'vue'
import ConsentContainerComponent from './ConsentContainer.vue'
import type { ConsentContext } from './consent-types'

const STORAGE_KEY = 'CONSENT'

const DEFAULT_DECISION_VALUE: ConsentContext['decision'] = {
  initDecision: null,
  timestamp: null,
  statistics: false,
  other: false,
}

export default defineNuxtPlugin({
  enforce: 'pre',
  name: 'consent-plugin',
  setup(nuxtApp) {
    const decision = getStorageValue()

    const $consent: ConsentContext = reactive({
      consentOpened: decision.timestamp === null,
      configOpened: false,
      decision,
      closeConfig() {
        $consent.configOpened = false
      },
      openConfig() {
        $consent.configOpened = true
      },
      openBanner() {
        $consent.consentOpened = true
      },
      saveChoice({ statistics, other }) {
        Object.assign($consent.decision, {
          statistics,
          other,
          timestamp: new Date(),
          initDecision: $consent.decision.initDecision === null,
        })
        $consent.consentOpened = false
        $consent.configOpened = false
        setStorageValue($consent.decision)
      },
      get isOpened() {
        return $consent.consentOpened || $consent.configOpened
      },
    } satisfies ConsentContext)

    Object.defineProperty(nuxtApp.vueApp, '$consent', { get: () => $consent })
    nuxtApp.provide('consent', $consent)
    nuxtApp.vueApp.component('ConsentContainer', ConsentContainerComponent)
  },
  hooks: {
    'app:mounted'(app) {
      let consentTarget = document.getElementById(
        'consent-target',
      ) as HTMLDivElement
      if (!consentTarget) {
        consentTarget = document.createElement('div')
        consentTarget.setAttribute('id', 'consent-target')
      }
      const consentNodeTree = h(ConsentContainerComponent)
      consentNodeTree.appContext = app._context
      document.body.appendChild(consentTarget)
      render(consentNodeTree, consentTarget)
    },
  },
})

function getStorageValue(): ConsentContext['decision'] {
  if (typeof window === 'undefined') return DEFAULT_DECISION_VALUE

  if (isInIFrame()) {
    return {
      timestamp: new Date(),
      statistics: true,
      other: true,
      pseudoconsent: true, // @TODO(Ivan) What is `pseudoconsent`?
    }
  }

  const storageValue = window.localStorage.getItem(STORAGE_KEY)
  if (!storageValue) return DEFAULT_DECISION_VALUE
  const parsedValue = JSON.parse(storageValue)
  if (typeof parsedValue.timestamp === 'string') {
    parsedValue.timestamp = new Date(parsedValue.timestamp)
  }
  return parsedValue
}

function setStorageValue(value: ConsentContext['decision']) {
  if (typeof window === 'undefined') return
  if (isInIFrame()) return
  if (typeof value !== 'object') {
    window.localStorage.removeItem(STORAGE_KEY)
    return
  }
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value))
}

function isInIFrame() {
  return (
    typeof window !== 'undefined' &&
    (window.parent !== window || window.location.search.includes('iniframe'))
  )
}
