// Need to leave this import instead of '~/constants' fo working unit tests
import { format } from 'date-fns'
import { EVERY_THIRD_DIGIT } from '~/constants/regex'

interface toFixedProps {
  number: number
  afterDotMaxNumber?: number
  forceConvert?: boolean
}

export const toFixed = ({
  number,
  afterDotMaxNumber = 2,
  forceConvert,
}: toFixedProps): number | string => {
  if (typeof number !== 'number') return number

  if (forceConvert) {
    return number.toFixed(afterDotMaxNumber)
  }

  const numberAsString = number.toString()

  if (!numberAsString.includes('.')) {
    return String(number)
  }

  const countAfterDot = numberAsString.split('.')[1].length

  if (countAfterDot <= afterDotMaxNumber) {
    return parseFloat(numberAsString).toFixed(countAfterDot)
  }

  return parseFloat(numberAsString).toFixed(afterDotMaxNumber)
}

/**
 * value - number/string
 * symbol - string
 * */
export const currencyConverter = (
  value: string | number | null | undefined,
  symbol?: string,
) => {
  if (!(typeof value === 'string' || typeof value === 'number') || value === '')
    return value

  const convertedValue = value.toString().replace(EVERY_THIRD_DIGIT, ' ')

  return `${convertedValue}${symbol ? ` ${symbol}` : ''}`
}

export const serializeSentenceWithLink = (
  sentence: string,
  tag: string | undefined = 'link',
): { labelBefore: string; linkText: string; labelAfter: string } => {
  const regex = new RegExp(`</?${tag}>`)
  const [labelBefore, linkText, labelAfter] = sentence.split(regex)

  return { labelBefore, linkText, labelAfter }
}

export const isStandaloneApplication = (): boolean => {
  return window.matchMedia('(display-mode: standalone)').matches
}

export const isLandscape = (): boolean => {
  return (
    import.meta.client && window.matchMedia('(orientation: landscape)').matches
  )
}

export function unwrapNullableData<T extends object>(
  data: MaybeRef<T | null | undefined>,
): { [K in keyof T]: ComputedRef<T[K]> } {
  return new Proxy({} as any, {
    get(_, name) {
      return computed(() => {
        return unref(data)?.[name as keyof T]
      })
    },
  })
}

export function calculatePixelWidth(textLength: string) {
  const canvas: HTMLCanvasElement = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.font = '16px Lato'
    return Math.round(ctx.measureText(textLength.trim()).width)
  }
  return 0
}

export function convertDateToString(formDataField: Date | string): string {
  if (formDataField instanceof Date) {
    return format(formDataField as Date, 'yyyy-MM-dd')
  }
  return formDataField as string
}
