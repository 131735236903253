<script setup lang="ts">
import { Icon } from 'webcc-ui-components'

const { locale } = useI18n()

const interhomeGroupLink = computed(() => {
  return 'https://www.interhome.group/'.concat(locale.value ?? '')
})

const interhomeLink = computed(() => {
  switch (locale.value) {
    case 'en':
      return 'https://www.interhome.com/'
    case 'de':
      return 'https://www.interhome.de/'
    case 'fr':
      return 'https://www.interhome.fr/'
    case 'cs':
      return 'https://www.interhome.cz/'
    case 'es':
      return 'https://www.interhome.es/'
    case 'hr':
      return 'https://www.interhome.hr/'
    case 'it':
      return 'https://www.interhome.it/'
    case 'nl':
      return 'https://www.interhome.nl/'
    case 'pt':
      return 'https://www.interhome.pt/'
    case 'pl':
      return 'https://www.interhome.pl/'
    default:
      return 'https://www.interhome.com/'
  }
})

const interchaletLink = computed(() => {
  switch (locale.value) {
    case 'en':
      return 'https://www.interchalet.co.uk/'
    case 'de':
      return 'https://www.interchalet.de/'
    case 'fr':
      return 'https://www.interchalet.fr/'
    case 'es':
      return 'https://www.interchalet.es/'
    case 'it':
      return 'https://www.interchalet.it/'
    case 'nl':
      return 'https://www.interchalet-vakantiehuis.nl/'
    case 'pl':
      return 'https://www.interchalet.pl/'
    default:
      return 'https://www.interchalet.co.uk/'
  }
})
</script>

<template>
  <div class="w-fit bg-bgr-100 md:flex md:h-[76px] md:items-center">
    <a v-if="!$auth?.loggedIn" target="_blank" :href="interhomeGroupLink">
      <Icon
        graphic="interhome-group-logo"
        class="h-10 object-contain md:mr-6 md:w-[152px] lg:mr-10"
      />
    </a>
    <NuxtLink v-else :to="$ROUTE.INDEX" class="link-sm">
      <Icon
        graphic="interhome-group-logo"
        class="h-10 object-contain md:mr-6 md:w-[152px] lg:mr-10"
      />
    </NuxtLink>
    <div class="flex items-center">
      <a target="_blank" :href="interhomeLink">
        <Icon graphic="interhome-grey-logo" class="h-7 w-[105px]" />
      </a>
      <div class="mx-4 h-[30px] w-px self-center bg-bgr-200" />
      <a target="_blank" :href="interchaletLink">
        <Icon graphic="interchalet-grey-logo" class="h-7 w-[85px]" />
      </a>
    </div>
  </div>
</template>
