import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { MaybeRef, MaybeRefOrGetter } from 'vue'
import type { DefaultError } from '@tanstack/query-core'
import {
  addDays,
  differenceInCalendarDays,
  isToday,
  isTomorrow,
  isWithinInterval,
  startOfDay,
} from 'date-fns'
import type { components } from '~/schema'
import type {
  NormalizedReservation,
  Reservation,
} from '~/composables/reservations'
import { useApiFetch } from '~/composables/api'
import type { ReservationNotification } from '~/composables/inbox'
import { TravellerContactType } from '~/constants/enums'

type ReservationID = string

export type ReservationDetails = components['schemas']['ReservationDetails']

export function useReservationPopup() {
  const id = useState<ReservationID | null>('reservation-modal-id', () => null)
  const opened = computed(() => !!id.value)
  const { data, isFetched, isError, isLoading } = useReservationQuery(id)
  const { mutate, isPending } = useReservationDeleteMutation()
  return {
    opened,
    isFetched,
    isError,
    isLoading,
    reservation: data,
    isRemoving: isPending,
    remove() {
      const reservationId = toValue(id)
      if (!reservationId) return
      return new Promise<void>((resolve, reject) => {
        mutate(reservationId, {
          onSuccess() {
            resolve()
          },
          onError(error) {
            reject(error)
          },
        })
      })
    },
    open(reservationId: MaybeRefOrGetter<ReservationID>) {
      id.value = toValue(reservationId)
    },
    close() {
      id.value = null
    },
  }
}

export function useReservationQuery(
  reservationId?: MaybeRefOrGetter<ReservationID | null>,
) {
  const fetch = useApiFetch()
  return useQuery({
    enabled: computed(() => !!toValue(reservationId)),
    queryKey: ['reservation', reservationId],
    networkMode: 'offlineFirst',
    queryFn({ queryKey: [_, id] }) {
      return fetch<ReservationDetails>(`/api/reservations/${toValue(id)}`)
    },
    select(data) {
      return normalizeReservation(data)
    },
  })
}

export function useReservationDeleteMutation() {
  const queryClient = useQueryClient()
  const fetch = useApiFetch()
  return useMutation<boolean, DefaultError, MaybeRef<ReservationID>>({
    mutationFn(reservationId) {
      return fetch<boolean>(`/api/owner-bookings/${toValue(reservationId)}`, {
        method: 'delete',
        body: {},
      })
    },
    onSuccess: (_, reservationId) => {
      // Invalidate and re-fetch
      queryClient.invalidateQueries({ queryKey: ['reservations'] })
      queryClient.invalidateQueries({
        queryKey: ['reservation', reservationId],
      })
      queryClient.invalidateQueries({ queryKey: ['calendar'] })
      queryClient.invalidateQueries({ queryKey: ['inbox-notifications'] })
    },
  })
}

export function normalizeReservation(
  reservation: Reservation,
): NormalizedReservation {
  return {
    ...reservation,
    ...convertTravellerContact(reservation.travellerContact),
    showTravellerContact: reservation.travellerContact?.show,
    customerName: reservation.travellerName,
  }
}

export function convertTravellerContact(
  travellerContact?: NonNullable<Reservation['travellerContact']>,
) {
  const {
    [TravellerContactType.EMAIL]: email,
    [TravellerContactType.MOB]: mobile,
    [TravellerContactType.PHONE]: phone,
    [TravellerContactType.PHONE_BUSINESS]: phoneBusiness,
  } = Object.fromEntries(
    (travellerContact?.value || []).map(
      ({ contactInfo: { type, contact } }) => [type, contact],
    ),
  )

  const anyPhone = mobile || phone || phoneBusiness

  return { email, phone: anyPhone }
}

export enum UrgencyStatus {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  CurrentGuest = 'CurrentGuest',
  ArrivingInDays = 'ArrivingInDays',
}

export type ReservationTag = Pick<
  ReservationNotification,
  | 'startDate'
  | 'endDate'
  | 'isOwnerBooking'
  | 'isMyRent'
  | 'isCommentsAvailable'
> & { canceled?: boolean; houseClosing?: boolean }

export function getReservationUrgencyTag(reservation: ReservationTag):
  | {
      status: UrgencyStatus
      payload?: number
    }
  | undefined {
  const currentDate = startOfDay(new Date())
  const startDate = startOfDay(new Date(reservation.startDate))
  const endDate = startOfDay(new Date(reservation.endDate))

  if (isToday(startDate)) return { status: UrgencyStatus.Today }

  if (isTomorrow(startDate)) return { status: UrgencyStatus.Tomorrow }

  if (
    isWithinInterval(currentDate, {
      start: startOfDay(addDays(startDate, 1)),
      end: endDate,
    })
  ) {
    return { status: UrgencyStatus.CurrentGuest }
  }

  const diff = differenceInCalendarDays(startDate, currentDate)

  if (diff >= 2 && diff <= 7) {
    return { status: UrgencyStatus.ArrivingInDays, payload: diff }
  }
}

export function convertReservationToReservationTag(reservation: Reservation) {
  return {
    startDate: reservation.arrivalDate,
    endDate: reservation.departureDate,
    isOwnerBooking: reservation.ownerBooking,
    isMyRent: reservation.myRent,
    houseClosing: reservation.houseClosing,
    canceled: reservation.canceled,
  } as ReservationTag
}
