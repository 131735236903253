import {
  defineNuxtRouteMiddleware,
  useAuth,
  useNuxtApp,
  navigateTo,
} from '#imports'

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()
  const { $ROUTE, $GUEST_ALLOWED_ROUTE } = nuxtApp
  const auth = useAuth()

  if (
    !auth.loggedIn &&
    !Object.values($GUEST_ALLOWED_ROUTE).includes(to.path)
  ) {
    return navigateTo($ROUTE.LOGIN)
  }

  if (
    auth.user?.requireNewPassword &&
    to.path !== $ROUTE.PROFILE_CHANGE_PASSWORD
  ) {
    return navigateTo($ROUTE.PROFILE_CHANGE_PASSWORD)
  }

  if (auth.loggedIn && Object.values($GUEST_ALLOWED_ROUTE).includes(to.path)) {
    if (import.meta.client) return abortNavigation()
    return navigateTo($ROUTE.INDEX)
  }

  if (
    auth.loggedIn &&
    Boolean(to.query.uid) &&
    to.query.uid !== auth.user?.userID
  ) {
    if (to.query.uid !== auth.user?.userID) return await auth.logout()
  }
})
