import type { NuxtApp } from '#app'
import { defineNuxtPlugin } from '#imports'

const COOKIE_SHOW_TRANSLATIONS_KEY = 'show-translation-keys'

export default defineNuxtPlugin({
  name: 'translations-key',
  enforce: 'post',
  setup(nuxtApp) {
    const context = nuxtApp as unknown as NuxtApp
    const reference = { replaced: false, fn: null }
    const showTranslationKeyCookie = useCookie(COOKIE_SHOW_TRANSLATIONS_KEY)

    function replace() {
      // save original function to original fn reference object
      if (reference.fn === null) reference.fn = context.$i18n.t
      reference.replaced = true
      context.$i18n.t = context.vueApp.config.globalProperties.$t = (
        key: string,
      ) => key
      showTranslationKeyCookie.value = JSON.stringify(true)
    }

    function restore() {
      reference.replaced = false
      context.$i18n.t = context.vueApp.config.globalProperties.$t = reference.fn
      showTranslationKeyCookie.value = JSON.stringify(false)
    }

    function toggle() {
      reference.replaced ? restore() : replace()
    }

    if (import.meta.client) {
      useEventListener('keydown', (event) => {
        // Check if the Ctrl (or Cmd for Mac) and Shift keys are pressed, and if the pressed key is '7'
        if (
          (event.ctrlKey || event.metaKey) &&
          event.shiftKey &&
          (event.code === 'Digit7' || event.key === '7')
        ) {
          toggle()
          window.location.reload()
        }
      })
    }

    // if the cookie show-translation-keys is set, replace by default
    // should help translators to enable it immediately
    if (showTranslationKeyCookie.value) {
      replace()
    }
  },
})
