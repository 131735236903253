export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('vue:error', (..._args) => {
    if (import.meta.client) {
      showError({
        statusCode: 500,
        // we ignore this text message
        statusMessage: 'Something went wrong',
      })
    }
  })
})
