<script setup lang="ts">
import { Btn } from 'webcc-ui-components'

const circleRows = ref([
  Array.from({ length: 5 }, (_, i) => '' + (i + 1)),
  Array.from({ length: 5 }, (_, i) => '' + (i + 6)),
])

defineEmits<{
  (e: 'click', ratingValue: string): void
}>()
</script>

<template>
  <div class="flex flex-col space-y-4">
    <p class="text-sm font-normal leading-4 text-white sm:hidden">
      {{ $t('components.shareFeedback.notLikely') }}
    </p>

    <div class="flex flex-col gap-y-4 sm:flex-row sm:gap-x-2">
      <div
        v-for="(circleRow, rowIndex) in circleRows"
        :key="'circleRow-' + rowIndex"
        class="flex justify-between sm:gap-x-2"
      >
        <Btn
          v-for="(ratingValue, index) in circleRow"
          :key="'circle-' + index"
          variant="neutral"
          class="w-11 rounded-full text-txt-400"
          size="lg"
          :label="ratingValue"
          @click="$emit('click', ratingValue)"
        />
      </div>
    </div>

    <div class="flex xs:justify-end sm:justify-between">
      <p class="hidden text-sm font-normal leading-4 text-white sm:block">
        {{ $t('components.shareFeedback.notLikely') }}
      </p>
      <p class="text-right text-sm font-normal leading-4 text-white">
        {{ $t('components.shareFeedback.extremelyLikely') }}
      </p>
    </div>
  </div>
</template>
