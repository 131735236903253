import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (sentry.disabled) {
    console.warn('Sentry is disabled, skipping Sentry client plugin')
    return
  }

  if (!sentry.dsn) {
    console.warn(
      'Sentry DSN not set, skipping Sentry initialization from server',
    )
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration({
        unmask: ['#login-ownerNumber', '[data-id="navigation-bar"]'],
      }),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 1.0, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', 'https://your-server.com'],

    replaysSessionSampleRate: 0.1, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    denyUrls: [
      /clarity\.ms/i,
      /google/i,
      /bugherd\.com/i,
      /sentry\.io/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],

    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  })

  const auth = useAuth()

  auth.loggedIn && Sentry.setUser({ id: auth.profile.userID })

  auth.on('login-finish', (user) => {
    Sentry.setUser({ id: user.userID })
  })

  console.log('Sentry initialized on client side')
})
