import { intlFormat } from 'date-fns'

export const WEB_PUSH_PREFIX = 'webpush:'

export type TagVariant =
  | 'cta'
  | 'warning'
  | 'highlight'
  | 'info'
  | 'neutral'
  | 'theme'
  | 'error'
  | 'success'

export type BreadcrumbItem = {
  label: string
  url?: string
}

export type DateRange = {
  from: string | Date
  to: string | Date
}

export const DATE_FORMAT_DDMMYYYY: Parameters<typeof intlFormat>[1] = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}
