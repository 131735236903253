import type { NuxtApp } from '@nuxt/schema'
import { defineNuxtPlugin } from '#imports'

declare module '@nuxt/schema' {
  interface NuxtApp {
    currency(amount: number, currencySymbol: string, currency: string): string
  }
}

export default defineNuxtPlugin<{ currency: NuxtApp['currency'] }>({
  setup() {
    return {
      provide: {
        currency(amount, currencySymbol, currency) {
          const number = amount ?? 0

          const formatNumber = number
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

          return `${formatNumber} ${currencySymbol ?? currency}`
        },
      },
    }
  },
})
