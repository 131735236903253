type Request = Parameters<typeof $fetch>[0]
type Options = Parameters<typeof $fetch>[1]

export function useApi<T = unknown>(url: string, options: Options = {}) {
  const requestFetch = useRequestFetch()
  const $auth = useAuth()
  const nuxt = useNuxtApp()
  return requestFetch<T>(url, {
    ...options,
    headers: {
      ...useRequestHeaders(['cookie']),
      'Accept-Language': nuxt.$i18n?.locale?.value || 'en',
      ...options.headers,
    },
    retry: 0,
    onResponse(context) {
      if (context.response.status === 401) {
        $auth.logout()
      }
    },
  })
}

export function useApiFetch() {
  const requestFetch = useRequestFetch()
  const $auth = useAuth()
  const nuxt = useNuxtApp()
  return <T = unknown>(url: Request, options: Options = {}) =>
    requestFetch<T>(url, {
      ...options,
      headers: {
        ...useRequestHeaders(['cookie']),
        'Accept-Language': nuxt.$i18n?.locale?.value || 'en',
        ...options.headers,
      },
      retry: 0,
      async onResponse(context) {
        await options.onResponse?.(context)
        if (context.response.status === 401) {
          $auth.logout()
        }
      },
    })
}
