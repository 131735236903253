<script setup lang="ts">
defineOptions({ name: 'PwaBanner' })

const { $device, $pwaBanner } = useNuxtApp()
const obstacle = useObstacle()
const { isModalOpen } = useNavigation()
</script>

<template>
  <div
    v-if="$pwaBanner.visible && !isModalOpen"
    data-id="pwa-banner"
    class="pwa-banner pwa-banner-safe-area fixed inset-0 z-10"
    :style="{ bottom: obstacle.obstaclePixelHeight.value }"
  >
    <PwaAndroidBanner v-if="$device.isAndroid" />
    <PwaIosBanner v-if="$device.isIOS" />
  </div>
</template>

<style scoped>
.pwa-banner {
  pointer-events: none;
}

.pwa-banner > * {
  pointer-events: auto;
}
</style>
